import React from 'react';
import loadable from '@loadable/component';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {BreakdownTypes} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {useControllerProps} from '../../ControllerContext';
import {useFunctionResultObservation} from '../../../../../hooks/useFunctionResultObservation';
import {PaymentAuthorizedArgs} from '@wix/cashier-express-checkout-widget/src/types/ExternalContract';
import {IControllerProps} from '../../../../../types/app.types';
import s from './CashierExpressButtons.scss';
import {round} from '../../../../../domain/utils/math.utils';

const CashierExpressCheckoutWidgetLazy = loadable<WidgetProps>(() =>
  import(
    /* webpackChunkName: "CashierExpressCheckoutWidget" */ '@wix/cashier-express-checkout-widget/dist/src/lazy'
  ).then((module) => module.CashierExpressCheckoutWidget)
);

export function CashierExpressButtons() {
  const {withObservation} = useFunctionResultObservation();
  const {
    dynamicPaymentOptionsProps: {
      currency,
      meta,
      demoMode,
      locale,
      domain,
      orderItems,
      buttonStyle,
      getButtonTheme,
      shouldRequestShipping,
    },
    shouldShowShippingMethod,
    shouldRoundItemsTotalWithAdditionalFees,
  } = useControllerProps().cashierExpressStore;
  const observerCashierExpressStore = useControllerProps()
    .cashierExpressStore as IControllerProps['cashierExpressStore'];
  const accessibilityEnabled = useControllerProps().host.accessibilityEnabled;

  const onClick = withObservation(observerCashierExpressStore, 'onClick');
  const onShippingContactSelected = withObservation(observerCashierExpressStore, 'onShippingContactSelected');
  const onShippingMethodSelected = withObservation(observerCashierExpressStore, 'onShippingMethodSelected');
  const onPaymentAuthorized = withObservation(observerCashierExpressStore, 'onPaymentAuthorized');

  const totals = useControllerProps().cartStore.cart.totals;
  const additionalFees = useControllerProps().cartStore.cart.additionalFees;
  const getAdditionalFeesOrderItems = (fees) =>
    fees?.map((fee) => ({
      id: fee.code,
      name: fee.name,
      price: fee.totalPrice?.toString(),
      quantity: 1,
    })) || [];
  const additionalFeesAsOrderItems = getAdditionalFeesOrderItems(additionalFees);
  const orderItemsAndAdditionalFees = [...orderItems, ...additionalFeesAsOrderItems];
  const {shouldDisplayTax, shouldDisplayDelivery} = useControllerProps().orderStore;
  const additionalFeesTotalCost = totals.additionalFeesTotal || 0;
  const combinedCost = totals.itemsTotal + additionalFeesTotalCost;
  const itemsTotalWithAdditionalFees = shouldRoundItemsTotalWithAdditionalFees ? round(combinedCost) : combinedCost;
  const payment = {
    total: totals.total.toString(),
    paymentBreakdown: {
      [BreakdownTypes.ItemsTotal]: itemsTotalWithAdditionalFees.toString(),
      [BreakdownTypes.Shipping]: shouldDisplayDelivery ? totals.shipping.toString() : '0',
      [BreakdownTypes.Tax]: shouldDisplayTax ? totals.tax.toString() : '0',
      [BreakdownTypes.Discount]: totals.discount.toString(),
    },
  };

  return (
    <div data-hook="cashier-express-buttons-container" className={s.cashierExpressButtonsContainer}>
      <CashierExpressCheckoutWidgetLazy
        theme={getButtonTheme}
        buttonStyle={buttonStyle}
        currency={currency}
        locale={locale}
        meta={meta}
        onClick={onClick}
        onPaymentAuthorized={(paymentInfo: PaymentAuthorizedArgs) =>
          onPaymentAuthorized(paymentInfo, accessibilityEnabled)
        }
        onShippingContactSelected={onShippingContactSelected}
        onShippingMethodSelected={shouldShowShippingMethod ? onShippingMethodSelected : undefined}
        orderItems={orderItemsAndAdditionalFees}
        paymentAmount={payment.total}
        paymentBreakdown={payment.paymentBreakdown}
        paymentLabel="forApplePay"
        demoMode={demoMode}
        domain={domain}
        requestShipping={shouldRequestShipping}
      />
    </div>
  );
}
